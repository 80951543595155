<template>
	<v-dialog
		v-model="welcomeMessage"
		scrollable
		max-width="650"
		max-height="500"
		persistent
		@keydown.esc="welcomeMessageOff"
		:fullscreen="$vuetify.breakpoint.xsOnly"
	>
		<v-card>
			<v-card-text class="mt-5 text-center text-body-1">
			
				<br/>
				Welcome to <strong>ALSTERPH</strong>, your reliable partner.<br/><br/>

				<font v-if="welcomeMessage == 1">Please complete your application to increase your chance of getting hired.</font>
				
				<font v-else-if="welcomeMessage == 2">Hope your doing great, keep in touch with us by updating your information.</font>

				<font v-else>It's nice your back, please update your application to get back on track.</font>
				
				<br/><br/>
				
				You may visit the <a @click="openHelpCenter" class="text-decoration-none">Help Center</a> section in the menu if you need help.
				
			</v-card-text>
			
			<v-card-actions class="d-flex justify-center">
				<v-btn
					outlined
					@click="closeDialog"
					color="primary"
					class="mb-5"
				>
					GET STARTED
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import { OFF_WELCOME_MESSAGE } from '@/store/actions/auth';

export default {
	methods: {
		closeDialog(){
			this.$store.dispatch(OFF_WELCOME_MESSAGE);
		},
		openHelpCenter(){
			this.closeDialog();
			this.$router.push('/helpcenter');
		}
	},
	computed: {
		...mapGetters(['welcomeMessage']),
	},
}
</script>